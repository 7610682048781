import { ListItemButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CollapsableButton = styled(ListItemButton)(() => ({
  padding: 0,
  '&:hover': {
    backgroundColor: 'unset',
  },
  '& .MuiTouchRipple-root': {
    display: 'none',
  },
}));

export const CollapsableContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  borderLeft: `1px solid ${theme.palette.border.secondary}`,
  marginLeft: theme.spacing(7),
  paddingLeft: theme.spacing(6),
}));

export const ChildTypography = styled(
  Typography,
  { shouldForwardProp: (props) => props !== 'isActive' },
)<{ isActive: boolean }>(({ theme, isActive }) => ({
  color: isActive ? theme.palette.primary.main : theme.palette.text.disabled,
  padding: theme.spacing(2, 0),
  borderRight: `4px solid ${isActive ? theme.palette.primary.main : theme.palette.common.white}`,
  '&:hover': {
    color: theme.palette.text.primary,
  },
}));
