import { BankAccountCategory, FaAccountType } from 'constants/enums';
import { BankAccount } from 'models/bankAccount.interface';

export const isInternalAccount = (category?: BankAccountCategory | null) => (
  !!category && [BankAccountCategory.integrated, BankAccountCategory.wallet].includes(category)
);

export const isRecipientAccount = (type?: string) => (
  type === FaAccountType.recipient
);

export const getRelinkMessage = (account?: BankAccount | null, defaultMessage = 'warning.unlinkOneAccountMessage') => {
  if (account?.institution?.isMfa) {
    return 'warning.noteMFAAccountUpdate';
  }

  return account?.lastIdpUpdateStatusMessage || defaultMessage;
};
