import {
  runDeleteApiRequest, runGetApiRequest, runPostApiRequest, runUpdateApiRequest,
} from 'services/Api';

import {
  CREATE_RECIPIENT_API,
  DELETE_RECIPIENT_API,
  RECIPIENT_DETAILS_API,
  RECIPIENT_TRANSACTIONS_API,
  RECIPIENTS_API,
  UPDATE_RECIPIENT_API,
} from 'constants/apiRoutes';
import { Recipient } from 'models/recipient.interface';
import { formatRoute } from 'utils/formatters';

export const getRecipients = (params: { [key: string]: string | number | string[] } | URLSearchParams) => (
  runGetApiRequest(RECIPIENTS_API, params)
);

export const getRecipientDetails = (recipientId: string) => (
  runGetApiRequest(formatRoute(RECIPIENT_DETAILS_API, { recipientId }))
);

export const getRecipientTransactions = (recipientId: string, queryParams: { [key: string]: string | number }) => (
  runGetApiRequest(formatRoute(RECIPIENT_TRANSACTIONS_API, { recipientId }), queryParams)
);

export const createRecipient = (payload: Partial<Recipient>) => (
  runPostApiRequest(CREATE_RECIPIENT_API, payload)
);

export const updateRecipient = (recipientId: string, payload: Partial<Recipient>) => (
  runUpdateApiRequest(formatRoute(UPDATE_RECIPIENT_API, { recipientId }), payload)
);

export const deleteRecipient = (recipientId: string) => (
  runDeleteApiRequest(formatRoute(DELETE_RECIPIENT_API, { recipientId }))
);
