/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const ScheduleBubbleIcon = ({ size = 69 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.9521 4.83203C10.7366 4.83203 4.83301 10.7638 4.83301 18.0138C4.83301 25.2638 10.7366 31.1957 17.9521 31.1957C25.1675 31.1957 31.0711 25.2638 31.0711 18.0138C31.0711 10.7638 25.1675 4.83203 17.9521 4.83203ZM17.9521 9.22597C22.7624 9.22597 26.6981 13.1805 26.6981 18.0138C26.6981 22.8472 22.7624 26.8017 17.9521 26.8017C13.1417 26.8017 9.20602 22.8472 9.20602 18.0138C9.20602 13.1805 13.1417 9.22597 17.9521 9.22597ZM39.8171 9.22597V13.6199H34.8719C35.2326 15.026 35.4441 16.4935 35.4441 18.0138C35.4441 20.347 34.9808 22.5682 34.1587 24.6048H46.3767L46.3809 48.7714H15.7655V35.4394C14.2372 35.2483 12.7722 34.8605 11.3925 34.298V48.7714C11.3925 51.1991 13.3495 53.1654 15.7655 53.1654H46.3767C48.7928 53.1654 50.7497 51.1991 50.7497 48.7714V13.6199H44.1902V9.22597H39.8171ZM19.9208 11.8606L16.2011 18.2327L21.014 23.0686L22.9827 20.8716L19.703 17.795L22.3251 13.1822L19.9208 11.8606Z"
      fill="#24605D"
    />
  </svg>
);

export default ScheduleBubbleIcon;
