const config = {
  env: 'DEV',
  firebaseConfig: {
    apiKey: 'AIzaSyBitpIven3fERLYVW1fUCf6zNxc2Ktxsqc',
    authDomain: 'primaryacct-devel.firebaseapp.com',
    projectId: 'primaryacct-devel',
    storageBucket: 'primaryacct-devel.appspot.com',
    messagingSenderId: '336045187641',
    appId: '1:336045187641:web:187232495a3db8f881a3e8',
    measurementId: 'G-D1XNCH3TZK',
  },
  siteId: 'primaryacct-devel',
  sentryConfiguration: {
    dsn: 'https://05c054ee7e1e4886b6f68dd7b6dcd077@o4504578189557760.ingest.sentry.io/4504578249392128',
  },
  uiBaseUrl: 'https://primaryacct-devel.web.app',
  apiBaseUrl: 'https://api-dev.primaryaccount.com/',
  fiServEnvUrl: 'https://ais-ui-uat.fiservapps.com/alldata/',
  intercomAppId: 'qlijpicu',
};

export default config;
