import {
  configureStore, ThunkAction, Action,
} from '@reduxjs/toolkit';

import bankAccountReducer from 'store/bank-account-details/bankAccountDetailsSlice';
import bankAccountsReducer from 'store/bank-accounts/bankAccountsSlice';
import contactsReducer from 'store/contacts/contactsSlice';
import createTransactionReducer from 'store/create-transaction/createTransactionSlice';
import customerDetailsReducer from 'store/customer-details/customerDetailsSlice';
import customersReducer from 'store/customers/customersSlice';
import kybReducer from 'store/kyb/kybSlice';
import recipientDetailsReducer from 'store/recipient-details/recipientDetailsSlice';
import recipientsReducer from 'store/recipients/recipientsSlice';
import ruleHistoryReducer from 'store/rule-history/ruleHistorySlice';
import ruleManagementReducer from 'store/rule-management/ruleManagementSlice';
import scheduledTransactionDetailsReducer from 'store/scheduled-transaction-details/scheduledTransactionDetailsSlice';
import scheduledTransactionsReducer from 'store/scheduled-transactions/scheduledTransactionsSlice';
import subscriptionReducer from 'store/subscription/subscriptionSlice';
import transactionActivitySlice from 'store/transaction-activity/transactionActivitySlice';
import transactionApprovalDetailsReducer from 'store/transaction-approval-details/transactionApprovalDetailsSlice';
import transactionApprovalReducer from 'store/transaction-approval/transactionApprovalSlice';
import transactionBatchesReducer from 'store/transaction-batches/transactionBatchesSlice';
import transactionDetailsReducer from 'store/transaction-details/transactionDetailsSlice';
import transactionsSearchReducer from 'store/transactions-search/transactionsSearchSlice';
import userDetailsReducer from 'store/user-details/userDetailsSlice';
import userReducer from 'store/user/userSlice';
import usersReducer from 'store/users/usersSlice';

export const store = configureStore({
  reducer: {
    bankAccounts: bankAccountsReducer,
    bankAccountDetails: bankAccountReducer,
    createTransaction: createTransactionReducer,
    customers: customersReducer,
    customerDetails: customerDetailsReducer,
    loggedUser: userReducer,
    ruleHistory: ruleHistoryReducer,
    ruleManagement: ruleManagementReducer,
    transactionsSearch: transactionsSearchReducer,
    transactionDetails: transactionDetailsReducer,
    transactionBatches: transactionBatchesReducer,
    transactionApproval: transactionApprovalReducer,
    transactionApprovalDetails: transactionApprovalDetailsReducer,
    userDetails: userDetailsReducer,
    users: usersReducer,
    scheduledTransactions: scheduledTransactionsReducer,
    scheduledTransactionDetails: scheduledTransactionDetailsReducer,
    subscription: subscriptionReducer,
    kyb: kybReducer,
    recipients: recipientsReducer,
    recipientDetails: recipientDetailsReducer,
    contacts: contactsReducer,
    transactionActivity: transactionActivitySlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
