import {
  runDeleteApiRequest, runGetApiRequest, runUpdateApiRequest, runPostApiRequest,
} from 'services/Api';

import {
  WORKSPACE_USERS_API,
  DELETE_USER_API,
  GET_INVITATIONS_API,
  UPDATE_USER_API,
  UPDATE_USER_ROLE_API,
  UPDATE_USER_NOTIFICATIONS_API, UPDATE_USER_ALERTS_API,
} from 'constants/apiRoutes';
import { NotificationsPreference, User } from 'models/user.interface';
import { formatRoute } from 'utils/formatters';

export const getWorkspaceMembers = async (
  params: { [key: string]: string | number },
) => {
  const endpoint = formatRoute(WORKSPACE_USERS_API, params);
  return runGetApiRequest(endpoint);
};

export const getWorkspaceInvitations = async (
  params: { [key: string]: string | number },
) => {
  const endpoint = formatRoute(GET_INVITATIONS_API, params);
  return runGetApiRequest(endpoint);
};

export const updateUser = async (
  params: { [key: string]: string | number },
  payload: Partial<User>,
) => {
  const endpoint = formatRoute(UPDATE_USER_API, params);
  return runUpdateApiRequest(endpoint, payload);
};

export const updateUserRole = async (
  params: { [key: string]: string | number },
  payload: string[],
) => {
  const endpoint = formatRoute(UPDATE_USER_ROLE_API, params);
  return runPostApiRequest(endpoint, JSON.stringify(payload));
};

export const updateUserPreferences = async (
  params: { [key: string]: string },
  payload: { [key: string]: NotificationsPreference },
) => {
  const endpoint = formatRoute(UPDATE_USER_NOTIFICATIONS_API, params);
  return runPostApiRequest(endpoint, JSON.stringify(payload));
};

export const updateUserAlerts = async (
  params: { [key: string]: string },
  payload: { [key: string]: boolean },
) => {
  const endpoint = formatRoute(UPDATE_USER_ALERTS_API, params);
  return runPostApiRequest(endpoint, JSON.stringify(payload));
};

export const deleteUser = async (workspaceId: string, userId: string) => {
  const endpoint = formatRoute(DELETE_USER_API, { workspaceId, userId });
  return runDeleteApiRequest(endpoint);
};
