/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const SmsIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none">
    <path
      fill={color}
      d="M8.667 2c-4.05 0-7.333 2.985-7.333 6.667 0 1.89.872 3.591 2.264 4.803-.2.654-.616 1.304-1.382 1.884l-.001.002a.333.333 0 0 0 .186.636c1.294-.004 2.397-.556 3.275-1.249a7.8 7.8 0 0 0 1.305.407 5.437 5.437 0 0 1-.314-1.817c0-3.308 2.99-6 6.667-6 .92 0 1.8.17 2.598.476C15.47 4.533 12.396 2 8.668 2Zm4.667 6.667c-1.415 0-2.771.491-3.772 1.367-1 .875-1.562 2.062-1.562 3.3 0 1.237.562 2.424 1.562 3.3 1 .874 2.357 1.366 3.772 1.366.681 0 1.357-.116 1.99-.34.817.571 1.806.995 2.94.999a.333.333 0 0 0 .185-.638c-.611-.463-1.002-.973-1.237-1.492.933-.865 1.453-2.007 1.455-3.196 0-1.237-.562-2.424-1.562-3.3-1-.875-2.357-1.366-3.771-1.366Z"
    />
  </svg>
);

export default SmsIcon;
