const config = {
  env: 'PROD',
  firebaseConfig: {
    apiKey: 'AIzaSyBYq8qVzI_13CVJQsyH7CmhynQlheZHMPU',
    authDomain: 'primaryacct-prod.firebaseapp.com',
    projectId: 'primaryacct-prod',
    storageBucket: 'primaryacct-prod.appspot.com',
    messagingSenderId: '612931974639',
    appId: '1:612931974639:web:6ba21c840332587fb6721a',
    measurementId: 'G-50L9GPDMEC',
  },
  siteId: 'primaryacct-prod',
  sentryConfiguration: {
    dsn: 'https://05c054ee7e1e4886b6f68dd7b6dcd077@o4504578189557760.ingest.sentry.io/4504578249392128',
  },
  uiBaseUrl: 'https://console.primaryaccount.com',
  apiBaseUrl: 'https://api.primaryaccount.com/',
  fiServEnvUrl: 'https://ais-ui.fiservapps.com/alldata/?',
  intercomAppId: 'qlijpicu',
};

export default config;
