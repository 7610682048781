import { Grid, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface GridInformationLabelProps {
  label?: any;
  value?: string | number;
  isPlaceholder?: boolean;
  children?: ReactNode[] | ReactNode;
}

const GridInformationLabel: FC<GridInformationLabelProps> = ({
  label,
  value,
  children,
  isPlaceholder = false,
}) => (
  <>
    <Grid item xs={5} lg={4}>
      <Typography variant="body1" color="textSecondary">
        {label}
      </Typography>
    </Grid>

    <Grid item xs={6} lg={7}>
      {value && (
        <Typography
          variant="body1"
          fontWeight={600}
          className="text-ellipsis"
          color={isPlaceholder ? 'general.lightGrey6' : 'textPrimary'}
        >
          {value}
        </Typography>
      )}

      {children}
    </Grid>
  </>
);

export default GridInformationLabel;
