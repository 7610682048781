import {
  format, getWeekOfMonth, getWeeksInMonth, isLastDayOfMonth, parse,
} from 'date-fns';
import { isNil } from 'lodash';

import {
  CustomMonthlyRecurrence, DateFormats, RecurrenceEndPeriod, TransactionRecurrence,
} from 'constants/enums';
import { CustomRecurrence } from 'models/recurrence.interface';
import { formatDate, formatDayWithOrdinal, formatStringDate } from 'utils/formatters';

export const getCustomMonthlyRecurrenceLabels = (
  repeatPeriodMonthlyType: CustomMonthlyRecurrence,
  selectedDate: number | null,
) => {
  const date = !isNil(selectedDate) ? new Date(selectedDate) : new Date();
  const isLastDay = isLastDayOfMonth(date);

  if (repeatPeriodMonthlyType === CustomMonthlyRecurrence.monthlyOnCurrentDay) {
    return {
      id: isLastDay
        ? 'label.monthlyOnTheLastDay'
        : 'label.monthlyOnThe',
      values: isLastDay ? {} : { date: formatDayWithOrdinal(date) },
    };
  }

  return {
    id: 'label.monthlyOnTheLast',
    values: { date: formatDate(date, DateFormats.onlyWeekday) },
  };
};

export const getCustomRecurrenceLabels = (
  repeatPeriod: TransactionRecurrence,
  selectedDate: number | null,
) => {
  const date = !isNil(selectedDate) ? new Date(selectedDate) : new Date();
  const formattedDate = formatDate(date, DateFormats.onlyWeekday);

  if (repeatPeriod === TransactionRecurrence.daily) {
    return {
      id: 'label.dailyOn',
      values: { date: formattedDate },
    };
  }

  if (repeatPeriod === TransactionRecurrence.weekly) {
    return {
      id: 'label.weeklyOn',
      values: { date: formattedDate },
    };
  }

  return {
    id: 'label.yearlyOn',
    values: { date: formattedDate },
  };
};

export const getRecurrenceStringRule = (startTime: string, rule?: string) => {
  if (!rule) {
    return null;
  }

  if (rule === 'FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,TU,WE,TH,FR') {
    return {
      label: 'label.everyWeekday',
    };
  }

  if (rule === 'FREQ=DAILY;INTERVAL=1') {
    return {
      label: 'label.daily',
    };
  }

  const ruleChunks = rule.split(';').reduce((obj: Record<string, string>, chunk) => {
    const chunkParsed = chunk.split('=');
    return { ...obj, [chunkParsed[0]]: chunkParsed[1] };
  }, {});

  const freq = ruleChunks.FREQ;
  const interval = ruleChunks.INTERVAL || 1;
  const count = ruleChunks.COUNT;
  const until = ruleChunks.UNTIL;

  const untilParsed = until
    ? format(parse(until.split('T')[0], 'yyyyMMdd', new Date()), DateFormats.defaultDate)
    : undefined;
  const byMonthDay = ruleChunks.BYMONTHDAY;
  const date = new Date(startTime);
  const byPos = parseInt(ruleChunks.BYSETPOS, 10);

  switch (freq) {
    case TransactionRecurrence.daily: {
      if (count) {
        return {
          label: 'label.dailyNoccurrence',
          values: { count, interval },
        };
      }

      if (until) {
        return {
          label: 'label.dailyUntil',
          values: { date: formatStringDate(startTime), interval },
        };
      }

      return {
        label: 'label.dailyInterval',
        values: { interval },
      };
    }
    case TransactionRecurrence.weekly: {
      if (count) {
        return {
          label: 'label.weeklyNoccurrence',
          values: { count, interval, day: formatDate(date, DateFormats.onlyWeekday) },
        };
      }

      if (until) {
        return {
          label: 'label.weeklyUntil',
          values: { date: untilParsed, interval, day: formatDate(date, DateFormats.onlyWeekday) },
        };
      }

      return {
        label: 'label.weeklyOnInterval',
        values: { interval, day: formatDate(date, DateFormats.onlyWeekday) },
      };
    }
    case TransactionRecurrence.monthly: {
      if (byMonthDay) {
        if (count) {
          return {
            label: 'label.monthlyByMonthDayNoccurrence',
            values: { count, interval, day: formatDayWithOrdinal(date) },
          };
        }

        if (until) {
          return {
            label: 'label.monthlyByMonthDayUntil',
            values: { date: untilParsed, interval, day: formatDayWithOrdinal(date) },
          };
        }

        return {
          label: 'label.monthlyByMonthDay',
          values: { interval, day: formatDayWithOrdinal(date) },
        };
      }

      if (count) {
        return {
          label: 'label.monthlyByPosNoccurrence',
          values: {
            count, interval, pos: byPos, day: formatDate(date, DateFormats.onlyWeekday),
          },
        };
      }

      if (until) {
        return {
          label: 'label.monthlyByPosUntil',
          values: {
            date: untilParsed, interval, pos: byPos, day: formatDate(date, DateFormats.onlyWeekday),
          },
        };
      }

      return {
        label: 'label.monthlyByPos',
        values: { interval, pos: byPos, day: formatDate(date, DateFormats.onlyWeekday) },
      };
    }
    case TransactionRecurrence.yearly: {
      if (count) {
        return {
          label: 'label.annuallyNoccurrence',
          values: { count, interval, day: formatDate(date, DateFormats.shortDate) },
        };
      }

      if (until) {
        return {
          label: 'label.annuallyUntil',
          values: { date: untilParsed, interval, day: formatDate(date, DateFormats.shortDate) },
        };
      }

      return {
        label: 'label.annuallyInterval',
        values: { interval, day: formatDate(date, DateFormats.shortDate) },
      };
    }
    default:
      return null;
  }
};

export const getRecurrenceRuleForForm = (startTime: string, recurrenceRule: string): TransactionRecurrence => {
  const scheduleDate = new Date(startTime);
  const dayOfWeekValue = format(scheduleDate, 'EEEEEE').toUpperCase();
  const weekValue = getWeekOfMonth(scheduleDate);
  const week = weekValue === getWeeksInMonth(scheduleDate) ? '-1' : weekValue;
  const dayValue = format(scheduleDate, 'd');
  const monthValue = format(scheduleDate, 'M');

  switch (recurrenceRule) {
    case 'FREQ=DAILY;INTERVAL=1':
      return TransactionRecurrence.daily;
    case `FREQ=WEEKLY;INTERVAL=1;BYDAY=${dayOfWeekValue}`:
      return TransactionRecurrence.weekly;
    case `FREQ=MONTHLY;INTERVAL=1;BYSETPOS=${week};BYDAY=${dayOfWeekValue}`:
      return TransactionRecurrence.monthly;
    case `FREQ=YEARLY;INTERVAL=1;BYMONTH=${monthValue};BYMONTHDAY=${dayValue}`:
      return TransactionRecurrence.yearly;
    case 'FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,TU,WE,TH,FR':
      return TransactionRecurrence.everyWeekday;
    default: {
      return TransactionRecurrence.customCreated;
    }
  }
};

export const getRecurrenceCustomRuleForForm = (recurrenceRule: string): CustomRecurrence => {
  const ruleChunks = recurrenceRule.split(';').reduce((obj: Record<string, string>, chunk) => {
    const chunkParsed = chunk.split('=');
    return { ...obj, [chunkParsed[0]]: chunkParsed[1] };
  }, {});

  const until = ruleChunks.UNTIL;
  const untilParsed = until
    ? new Date(parse(until.split('T')[0], 'yyyyMMdd', new Date())).getTime()
    : undefined;
  const byMonthDay = ruleChunks.BYMONTHDAY;

  const byPos = parseInt(ruleChunks.BYSETPOS, 10);

  const custom: CustomRecurrence = {
    count: +ruleChunks.INTERVAL,
    repeatPeriod: ruleChunks.FREQ as TransactionRecurrence,
    endsPeriod: untilParsed ? RecurrenceEndPeriod.on : RecurrenceEndPeriod.never,
    occurrences: ruleChunks.COUNT ? +ruleChunks.COUNT : 0,
    endDate: untilParsed,
  };

  if (byMonthDay) {
    custom.repeatPeriodMonthlyType = CustomMonthlyRecurrence.monthlyOnCurrentDay;
  }

  if (byPos === -1) {
    custom.repeatPeriodMonthlyType = CustomMonthlyRecurrence.monthlyOnTheLastWeek;
  } else if (ruleChunks.BYSETPOS) {
    custom.repeatPeriodMonthlyType = CustomMonthlyRecurrence.monthlyOnCurrentWeek;
  }

  return custom;
};
