import MuiPaper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

export const Paper = styled(MuiPaper)(({ theme }) => ({
  minWidth: '500px',
  minHeight: '450px',
  padding: theme.spacing(0),
  boxShadow: 'none',
  overflow: 'hidden',
}));
