
const MuiButton = {
  styleOverrides: {
    root: {
      minWidth: '80px',
      padding: '12px 20px 12px 20px',
      borderRadius: '4px',
      boxShadow: 'none',
      fontSize: '15px',
      fontWeight: 600,
      lineHeight: '21px',

      '&:disabled': {
        color: 'inherit',
        opacity: 0.35,
      },

      '&:hover': {
        boxShadow: 'none',
      },
    },
    link: ({ theme }: any) => ({
      color: theme.palette.text.secondary,
      backgroundColor: 'transparent',
      padding: 0,

      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: 'transparent',
      },

      '&:disabled': {
        color: theme.palette.text.secondary,
        opacity: 0.35,
      },

      '&.button-link': {
        color: theme.palette.primary.main,
        textDecoration: 'underline',

        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
    }),
    dark: ({ theme }: any) => ({
      color: theme.palette.general.lightBlack,
      border: `1px solid ${theme.palette.general.lightBlack}`,
    }),
    containedPrimary: ({ theme }: any) => ({
      '&:disabled': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
      },

      '&:hover': {
        backgroundColor: theme.palette.general.darkGreen,
      },
      '& .MuiButton-startIcon svg path': {
        fill: theme.palette.common.white,
      },
    }),
    containedError: ({ theme }: any) => ({
      color: theme.palette.common.white,
    }),
    containedWarning: ({ theme }: any) => ({
      color: theme.palette.common.white,
    }),
    containedSecondary: ({ theme }: any) => ({
      color: theme.palette.general.lightBlack,
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.general.darkGrey}`,
      padding: '11px 19px 11px 19px',

      '& svg': {
        color: theme.palette.primary.main,
      },

      '&:disabled': {
        color: theme.palette.general.lightBlack,
        backgroundColor: theme.palette.common.white,
      },

      '&:hover': {
        backgroundColor: theme.palette.general.lightGreen4,
      },
    }),
    textPrimary: ({ theme }: any) => ({
      color: theme.palette.general.darkGrey,
      fontWeight: 400,
      padding: theme.spacing(3),

      '&:hover': {
        backgroundColor: theme.palette.general.lightGrey4,
      },

      '&:disabled': {
        color: theme.palette.general.darkGrey,
      },
    }),
    textError: ({ theme }: any) => ({
      fontWeight: 400,
      padding: theme.spacing(2, 3),

      '&:hover': {
        backgroundColor: theme.palette.general.lightGrey4,
      },

      '&:disabled': {
        color: theme.palette.general.darkGrey,
      },
    }),
    startIcon: () => ({
      fontSize: '0.833rem',
      '& path': {
        fill: 'currentColor',
      },

      '& > *:first-of-type': {
        '& path': {
          fill: 'currentColor',
        },
        fontSize: '0.833rem',
      },
    }),
    endIcon: () => ({
      fontSize: '0.833rem',
      '& path': {
        fill: 'currentColor',
      },

      '& > *:first-of-type': {
        '& path': {
          fill: 'currentColor',
        },

        fontSize: '0.833rem',
      },
    }),
  },
};

export default MuiButton;
