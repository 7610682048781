import { isNil } from 'lodash';

import { DateFormats } from 'constants/enums';
import { formatDate } from 'utils/formatters';
import {
  ApprovalTransactionFilterSchema,
} from 'utils/validation/transactionFormSchema';

interface FormattedFilters {
  createdDate?: string;
  amountFrom?: string;
  amountTo?: string;
}

export const getActiveFilters = (filters: ApprovalTransactionFilterSchema) => {
  const { amountRange, dateRange } = filters;
  const { min, max } = amountRange || {};
  const formattedFilters: FormattedFilters = {};

  if (!isNil(dateRange.from)) {
    formattedFilters.createdDate = formatDate(new Date(dateRange.from), DateFormats.transactionDate);
  }
  if (!isNil(min)) {
    formattedFilters.amountFrom = min;
  }
  if (!isNil(max)) {
    formattedFilters.amountTo = max;
  }

  return formattedFilters;
};
