import ComingSoon from 'components/_ComingSoon';
import Content from 'components/_Content';
import ErrorBoundary from 'components/_ErrorBoundary';
import PrivacyPolicy from 'components/_PrivacyPolicy';
import TermsAndConditions from 'components/_TermsAndConditions';
import KybRestrictionWrapper from 'containers/KybRestrictionWrapper';
import { JoinCustomer } from 'modules/accept-invitation';
import { TemporaryBankAccounts } from 'modules/add-bank-account';
import AccountDetails from 'modules/bank-account-details';
import BankAccountsOverview from 'modules/bank-accounts-overview';
import BillingDetails from 'modules/billing';
import { ChangePassword } from 'modules/change-password';
import SubscriptionFlow from 'modules/choose-subscription';
import CompanyDetails from 'modules/company-details';
import Contacts from 'modules/contacts';
import { CreateTransaction } from 'modules/create-transaction';
import EmailActions from 'modules/firebase-actions';
import ForgotPassword from 'modules/forgot-password';
import RecipientDetails from 'modules/recipient-details';
import ManageRecipients from 'modules/recipients';
import RulesHistory from 'modules/rules-history';
import RulesManagement from 'modules/rules-management';
import ScheduledTransactions from 'modules/scheduled-transactions';
import ScheduledTransactionsDetails from 'modules/scheduled-transactions-details';
import { Signin } from 'modules/signin';
import { Signup, CompleteProfile, ChooseWorkspace } from 'modules/signup';
import TeamManagement from 'modules/team-management';
import TransactionApproval from 'modules/transaction-approval';
import TransactionsActivity from 'modules/transactions-activity';
import TransactionApprovalDetails from 'modules/transactions-approval-details';
import TransactionDetails from 'modules/transactions-details';
import TransactionsSearch from 'modules/transactions-search';
import UserDetails from 'modules/user-details';
import UserPreferences from 'modules/user-preference';
import UserProfile from 'modules/user-profile';
import Customers from 'portal/customers';

import * as routes from 'constants/clientRoutes';
import { existingFlags, renderRouteComponent } from 'utils/featureFlag';

export const authRoutes = [
  {
    path: routes.SIGN_IN_ROUTE,
    element: <Signin />,
  },
  {
    path: routes.SIGN_UP_ROUTE,
    element: <Signup />,
  },
  {
    path: routes.FORGOT_PASSWORD_ROUTE,
    element: <ForgotPassword />,
  },
];

export const authCustomerRoutes = [
  {
    path: routes.COMPLETE_PROFILE_ROUTE,
    element: <CompleteProfile />,
  },
  {
    path: routes.SELECT_WORKSPACE_ROUTE,
    element: <ChooseWorkspace />,
  },
];

export const subscriptionRoutes = [
  {
    path: routes.CHOOSE_SUBSCRIPTION_PLAN_ROUTE,
    element: renderRouteComponent(existingFlags.subscriptions, SubscriptionFlow),
  },
  {
    path: routes.UPDATE_SUBSCRIPTION_PLAN_ROUTE,
    element: renderRouteComponent(existingFlags.subscriptions, SubscriptionFlow),
  },
];

export const openRoutes = [
  {
    path: routes.EMAIL_ACTIONS_ROUTE,
    element: <EmailActions />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.JOIN_WORKSPACE_ROUTE,
    element: <JoinCustomer />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.TERMS_ROUTE,
    element: <TermsAndConditions />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.PRIVACY_ROUTE,
    element: <PrivacyPolicy />,
    errorElement: <ErrorBoundary />,
  },
];

export const mainRoutes = [
  {
    path: routes.DASHBOARD_ROUTE,
    element: <BankAccountsOverview />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.INSIGHTS_ROUTE,
    element: <Content><ComingSoon /></Content>,
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.BANK_ACCOUNTS_TEMPORARY_ROUTE,
    element: <TemporaryBankAccounts />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.BANK_ACCOUNTS_DETAILS_ROUTE,
    element: <AccountDetails />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.SEARCH_TRANSACTIONS_ROUTE,
    element: <TransactionsSearch />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.QUICK_MOVE_MONEY_ROUTE,
    element: renderRouteComponent(
      existingFlags.quickMoveMoney,
      CreateTransaction,
      'label.workingOnImplementingThisFeatures',
    ),
    errorElement: <ErrorBoundary />,
  },
];

export const companySettingsRoutes = [
  {
    path: routes.COMPANY_PROFILE_ROUTE,
    element: <CompanyDetails />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.COMPANY_TEAM_MANAGEMENT_ROUTE,
    element: <TeamManagement />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.COMPANY_USER_ROUTE,
    element: <UserDetails />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.ALERTS_RULES_ROUTE,
    element: <RulesManagement />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.ALERTS_HISTORY_ROUTE,
    element: <RulesHistory />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.ALERT_CONTACTS_ROUTE,
    element: <Contacts />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.COMPANY_BILLING_ROUTE,
    element: <BillingDetails />,
    errorElement: <ErrorBoundary />,
  },
];

export const userSettingsRoutes = [
  {
    path: routes.USER_PROFILE_ROUTE,
    element: <UserProfile />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.USER_SECURITY_ROUTE,
    element: <ChangePassword />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.USER_PREFERENCES_ROUTE,
    element: <UserPreferences />,
    errorElement: <ErrorBoundary />,
  },
];

export const moveMoneyRoutes = [
  {
    path: routes.MOVE_MONEY_CREATE_ROUTE,
    element: (
      <KybRestrictionWrapper isVisible>
        {renderRouteComponent(existingFlags.moveMoney, CreateTransaction, 'label.workingOnImplementingThisFeatures')}
      </KybRestrictionWrapper>
    ),
    errorElement: <ErrorBoundary />,
  },
  // {
  //   path: routes.MOVE_MONEY_BATCHES_ROUTE,
  //   element: <TransactionBatches />,
  //   errorElement: <ErrorBoundary />,
  // },
  {
    path: routes.SCHEDULED_TRANSACTION_DETAILS_ROUTE,
    element: (
      <KybRestrictionWrapper isVisible>
        {renderRouteComponent(
          existingFlags.moveMoney,
          ScheduledTransactionsDetails,
          'label.workingOnImplementingThisFeatures',
        )}
      </KybRestrictionWrapper>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.MOVE_MONEY_SCHEDULED_TRANSACTIONS_ROUTE,
    element: (
      <KybRestrictionWrapper isVisible>
        {renderRouteComponent(
          existingFlags.moveMoney,
          ScheduledTransactions,
          'label.workingOnImplementingThisFeatures',
        )}
      </KybRestrictionWrapper>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.MOVE_MONEY_MANAGE_RECIPIENTS_ROUTE,
    element: <ManageRecipients />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.RECIPIENT_DETAILS_ROUTE,
    element: (
      <RecipientDetails />
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.TRANSACTION_DETAILS_ROUTE,
    element: (
      <KybRestrictionWrapper isVisible>
        {renderRouteComponent('move-money', TransactionDetails, 'label.workingOnImplementingThisFeatures')}
      </KybRestrictionWrapper>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.RECIPIENT_TRANSACTION_DETAILS_ROUTE,
    element: (
      <KybRestrictionWrapper isVisible>
        {renderRouteComponent('move-money', TransactionDetails, 'label.workingOnImplementingThisFeatures')}
      </KybRestrictionWrapper>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.MOVE_MONEY_TRANSACTION_APPROVAL_ROUTE,
    element: (
      <KybRestrictionWrapper isVisible>
        {renderRouteComponent('move-money', TransactionApproval, 'label.workingOnImplementingThisFeatures')}
      </KybRestrictionWrapper>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.MOVE_MONEY_ACTIVITY_ROUTE,
    element: (
      <KybRestrictionWrapper isVisible>
        {renderRouteComponent('move-money', TransactionsActivity, 'label.workingOnImplementingThisFeatures')}
      </KybRestrictionWrapper>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: routes.TRANSACTION_APPROVAL_DETAILS_ROUTE,
    element: (
      <KybRestrictionWrapper isVisible>
        {renderRouteComponent('move-money', TransactionApprovalDetails, 'label.workingOnImplementingThisFeatures')}
      </KybRestrictionWrapper>
    ),
    errorElement: <ErrorBoundary />,
  },
];

export const adminMainRoutes = [
  {
    path: routes.DASHBOARD_ROUTE,
    element: <Customers />,
    errorElement: <ErrorBoundary />,
  },
];
