import { format, getDate, parse } from 'date-fns';
import { User as FbUser } from 'firebase/auth';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { isNil, isNull } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { TimeZone } from 'timezones-list';

import {
  CurrencySymbol,
  CurrencyType,
  DateFormats,
  FlowActivityType,
  RecurrenceEndPeriod,
  TransactionRecurrence,
} from 'constants/enums';
import {
  currencySymbolByCurrencyLabel,
  DEFAULT_LANGUAGE,
  NOT_APPLICABLE,
  ONE_BILLION,
  ONE_HUNDRED_THOUSAND,
  ONE_MILLION,
} from 'constants/general';
import { BANK_ACCOUNT_TYPES_AS_LABELS } from 'constants/labels';
import AppLocale from 'languages/index';
import { BillingAddress } from 'models/address.interface';
import { Authority } from 'models/authority.interface';
import { BankAccount } from 'models/bankAccount.interface';
import { FlowActivityPrice, FormatPrice } from 'models/price.interface';
import { CustomRecurrence } from 'models/recurrence.interface';
import { ScheduleTime } from 'models/scheduleTime.interface';
import { CreateRuleTransaction, Transaction, TransactionSearch } from 'models/transaction.interface';
import { User } from 'models/user.interface';
import { getCustomMonthlyRecurrenceLabels, getCustomRecurrenceLabels } from 'utils/scheduleTime';

const USERNAME_SEP = ' ';

export const formatRoute = (route: string, params: Record<string, any>): string => {
  let formattedRoute = route;

  Object.keys(params).forEach((key) => {
    formattedRoute = formattedRoute.replace(`:${key}`, params[key]);
  });

  return formattedRoute;
};

export const formatUserInitials = (user: Partial<User> | null): string => {
  const { email, firstName, lastName } = user || {};
  const nameInitials = [
    firstName?.[0],
    lastName?.[0],
  ].filter((letter) => letter);
  const initials = nameInitials?.length ? nameInitials.join('') : email?.charAt(0).toUpperCase();

  return initials || '';
};

export const formatCompanyInitials = (name?: string): string => {
  if (!name) {
    return 'UN';
  }

  const parts = name?.split(' ');
  const initials = [parts?.[0]?.[0], parts?.[1]?.[0]].filter((letter) => letter).join('');
  return initials ? initials.toUpperCase() : 'UN';
};

export const formatUserName = (user: Partial<User> | null): string => {
  const { firstName, lastName } = user || {};
  const fullName = [firstName, lastName].filter((name) => name).join(' ');

  return fullName || NOT_APPLICABLE;
};

export const formatFullName = (firstName = '', lastName = '', middleName = ''): string => {
  const fullName = [firstName, middleName, lastName].filter((name) => name).join(' ');

  return fullName || NOT_APPLICABLE;
};

export const formatPrice = (props: FormatPrice): string => {
  const {
    price,
    currencySymbol = CurrencySymbol.usd,
    position = 'start',
    currency = CurrencyType.usd,
    showCompleteVersion = false,
    emptyState = NOT_APPLICABLE,
  } = props;

  if (isNil(price) || (!Number(price) && price !== 0)) {
    return emptyState;
  }

  const language = AppLocale[DEFAULT_LANGUAGE].locale;
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  const formatter = new Intl.NumberFormat(language, options);
  let formattedPrice = formatter.format(price < 0 ? price * (-1) : price);
  let startFormattedPrice = `${currencySymbol} ${formattedPrice}${showCompleteVersion ? ` ${currency}` : ''}`;

  if (price < 0) {
    startFormattedPrice = `-${currencySymbol} ${formattedPrice}${showCompleteVersion ? ` ${currency}` : ''}`;
  }

  formattedPrice = position === 'start' ? startFormattedPrice : `${formattedPrice} ${currencySymbol}`;

  return formattedPrice;
};

export const getMaskedAccountNumber = (accountNumber: string) => `**** ${accountNumber?.replace(/\*/g, '')}`;
export const getAccountLast4Numbers = (accountNumber: string) => accountNumber?.replace(/\*/g, '');

export const formatUsernameForFB = (firstName: string, lastName: string) => `${firstName}${USERNAME_SEP}${lastName}`;
export const formatFbUserToInAppUser = (fbUser: FbUser | null, firstName?: string | null, lastName?: string | null) => {
  const {
    displayName = '',
    email = '',
    photoURL,
    uid,
    metadata,
  } = fbUser || {};
  const [fbFirstName, ...fbLastName] = displayName?.split(USERNAME_SEP) || [];

  return {
    firstName: firstName || fbFirstName,
    lastName: lastName || fbLastName.join(' '),
    id: uid || '',
    email: isNull(email) ? '' : email,
    name: isNull(displayName) ? '' : displayName,
    photoUrl: isNull(photoURL) ? '' : photoURL,
    roles: [] as Authority[],
    // lastLoginAt exists on metadata
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    lastSignInAt: metadata?.lastLoginAt,
  };
};

export const formatDate = (date: Date|number, dateFormat = DateFormats.detailedDateTime) => (
  format(new Date(date), dateFormat)
);

export const formatStringDate = (date: string, dateFormat = DateFormats.detailedDateTime) => (
  format(rightStringDateFormat(date), dateFormat)
);

export const rightStringDateFormat = (date: string) => new Date(date.replace(/-/g, '/').replace(/T.+/, ''));

export const formatDayWithOrdinal = (date: Date|number) => {
  const formattedDate = new Date(date);
  const day = getDate(formattedDate);
  let ordinalSuffix;

  if (day >= 11 && day <= 13) {
    ordinalSuffix = 'th';
  } else {
    switch (day % 10) {
      case 1:
        ordinalSuffix = 'st';
        break;
      case 2:
        ordinalSuffix = 'nd';
        break;
      case 3:
        ordinalSuffix = 'rd';
        break;
      default:
        ordinalSuffix = 'th';
        break;
    }
  }

  return `${day}${ordinalSuffix}`;
};

export const formatFlowActivityPrice = ({ price, flowType, percentage }: FlowActivityPrice) => {
  if (isNull(price) || (!Number(price) && price !== 0)) {
    return NOT_APPLICABLE;
  }

  const language = AppLocale[DEFAULT_LANGUAGE].locale;
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  const formatter = new Intl.NumberFormat(language, options);
  const formattedPrice = formatter.format(price);
  const hasPercentage = percentage && !Number.isNaN(percentage);

  if (flowType === FlowActivityType.inflow) {
    return hasPercentage ? `+${formattedPrice} (${percentage}%)` : `+${formattedPrice}`;
  }

  return hasPercentage ? `${formattedPrice} (${percentage}%)` : `${formattedPrice}`;
};

export const formatChartBalance = ({ price }: { price: number }) => {
  const isNegative = price < 0;
  const positivePrice = isNegative ? price * (-1) : price;

  if (!Number(positivePrice) && positivePrice !== 0) {
    return '';
  }

  if (positivePrice >= ONE_HUNDRED_THOUSAND && positivePrice < ONE_BILLION) {
    return `${isNegative ? '-' : ''}${positivePrice / ONE_MILLION}M`;
  }

  if (positivePrice >= ONE_BILLION) {
    return `${isNegative ? '-' : ''}${positivePrice / ONE_BILLION}B`;
  }

  return price;
};

export const formatAcctNumberAndName = ({
  accountNumber,
  accountName,
  isAccountWrapped = false,
}: { accountNumber: string; accountName: string; isAccountWrapped?: boolean }) => {
  if (accountNumber && accountNumber.length > 4) {
    return isAccountWrapped
      ? `${accountName} (${getAccountLast4Numbers(accountNumber)})`
      : `${accountName} *${getAccountLast4Numbers(accountNumber)}`;
  }

  return accountName;
};

export const textTruncate = (str: string, removeCharacters: number): string => {
  if (str.length < removeCharacters) {
    return '';
  }

  return str.replace(str.substring(str.length - removeCharacters), '...');
};

export const formatTablePrice = ({
  amount,
  currency = CurrencyType.usd,
}: { amount?: number; currency?: CurrencyType|null }) => {
  const price = amount || 0;
  const currencySymbol = currency
    ? currencySymbolByCurrencyLabel[currency]
    : CurrencySymbol.usd;

  return formatPrice({ price, currencySymbol });
};

export const textTruncateLength = (str: string, length = 100, ending = '...'): string => {
  if (str && str.length > length) {
    return str.substring(0, length) + ending;
  }
  return str;
};

export const formatScheduleTime = (scheduleTime: ScheduleTime) => {
  const {
    date, hour, minutes, ampm,
  } = scheduleTime;

  if (date && hour && minutes) {
    const dateObject = new Date(date);
    const timeString = `${hour}:${minutes} ${ampm}`;
    const parsedTime = parse(timeString, 'h:mm a', new Date());

    return new Date(
      dateObject.getFullYear(),
      dateObject.getMonth(),
      dateObject.getDate(),
      parsedTime.getHours(),
      parsedTime.getMinutes(),
    );
  }

  return null;
};

export const formatRecurringSchedule = (recurrence: CustomRecurrence, scheduleDate: number | null) => {
  const messages = [];

  if (recurrence.count > 1) {
    messages.push({
      id: 'label.xTimes',
      values: { count: recurrence.count },
    });
  }

  if (recurrence.repeatPeriod === TransactionRecurrence.monthly && recurrence.repeatPeriodMonthlyType) {
    messages.push(getCustomMonthlyRecurrenceLabels(recurrence.repeatPeriodMonthlyType, scheduleDate));
  } else {
    messages.push(getCustomRecurrenceLabels(recurrence.repeatPeriod, scheduleDate));
  }

  if (recurrence.endsPeriod === RecurrenceEndPeriod.after) {
    messages.push({
      id: 'label.xOccurrences',
      values: { count: recurrence?.occurrences },
    });
  }

  if (recurrence.endsPeriod === RecurrenceEndPeriod.on && !isNil(recurrence?.endDate)) {
    messages.push({
      id: 'label.untilDate',
      values: { date: formatDate(recurrence?.endDate, DateFormats.defaultDate) },
    });
  }

  return messages;
};

export const formatAcctNumberNameAndType = (bankAccount: BankAccount) => {
  const {
    accountNumber, institution, nickname, type,
  } = bankAccount;
  const accountName = nickname || institution?.name;
  const accountType = type ? BANK_ACCOUNT_TYPES_AS_LABELS[type] : '';

  if (accountNumber && accountNumber.length > 4) {
    return (
      <FormattedMessage
        id="label.accountNameTypeAndNumber"
        values={{
          accountName,
          accountType: accountType ? <FormattedMessage id={accountType} /> : '',
          accountNumber: getAccountLast4Numbers(accountNumber),
        }}
      />
    );
  }

  return accountName;
};

export const formatRuleIdentifiers = (identifiers: Record<string, string>, bankAccounts: BankAccount[]) => (
  Object.values(identifiers).map((identifierId) => {
    const details = bankAccounts.find(({ id }) => id === identifierId);
    const name = details?.nickname || details?.institution?.name;
    if (details) {
      return [
        name,
        `*${getAccountLast4Numbers(details.accountNumber)}`,
      ].filter((item) => item).join(' ');
    }
    return identifierId;
  })
);

export const formatPhoneNumber = (number?: string | null) => {
  const phoneUtil = PhoneNumberUtil.getInstance();

  if (!number) {
    return NOT_APPLICABLE;
  }

  try {
    const phoneNumber = phoneUtil.parse(number, '');
    const nationalFormat = phoneUtil.format(phoneNumber, PhoneNumberFormat.NATIONAL);
    const countryCode = `+${phoneNumber.getCountryCode()}`;

    return `${countryCode} ${nationalFormat}`;
  } catch (error) {
    return NOT_APPLICABLE;
  }
};

export const formatPercentage = (
  { percentage, useZeroFormatting = false }: { percentage?: number; useZeroFormatting?: boolean },
) => {
  if (isNil(percentage)) {
    return NOT_APPLICABLE;
  }

  if (percentage < 1 && useZeroFormatting && percentage > 0) {
    return '<1%';
  }

  return `${percentage}%`;
};

export const formatAcctDisplayedName = (bankAccount: BankAccount) => (
  bankAccount?.nickname
  || formatAcctNumberAndName({
    accountNumber: bankAccount?.accountNumber, accountName: bankAccount?.name, isAccountWrapped: true,
  })
);

export const getMaskedCardNumberTail = (cardNumberTail?: string) => (
  `**** **** **** ${cardNumberTail || '****'}`
);

export const formatBillingAddressToAddress = (billingAddress: BillingAddress) => ({
  addressLine1: billingAddress?.addressLine1,
  city: billingAddress?.city,
  countryCode: billingAddress?.country,
  state: billingAddress?.state,
  zipCode: billingAddress?.postalCode,
});

export const formatTimezone = (timezone: TimeZone) => {
  const { tzCode, utc } = timezone;

  return {
    label: `${tzCode} (UTC${utc !== '+00:00' ? ` ${utc}` : ''})`,
    value: tzCode,
  };
};

export const extractTransactionInfoForRule = (
  { memo, trnType, amount }: Transaction | TransactionSearch,
): CreateRuleTransaction => ({
  memo, trnType, amount: +amount,
});

export const formatBankName = (bank: BankAccount) => (`${bank.nickname || bank.name} (${bank.accountNumber})`);
