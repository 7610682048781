import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const TransactionApproval = lazy(
  () => CustomLazyLoader(
    () => import(
      /* webpackChunkName: 'TransactionApproval' */ 'modules/transaction-approval/containers/TransactionApproval'
    ),
    'TransactionApproval',
  ),
);

export default TransactionApproval;
