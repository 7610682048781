import { styled } from '@mui/material/styles';

import { NAVBAR_HEIGHT } from 'constants/general';

export const ContentWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(7, 10, 10, 10),
  position: 'relative',
  width: '100%',
  minHeight: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
  backgroundColor: theme.palette.background.secondary,

  [theme.breakpoints.down('md')]: {
    width: 'fit-content',
    minWidth: '100%',
  },
}));
