import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const ScheduledTransactionDetails = lazy(
  () => CustomLazyLoader(
    () => (
      import(
        /* webpackChunkName: 'ScheduledTransactionDetails' */
        'modules/scheduled-transactions-details/containers/ScheduledTransactionDetails'
      )
    ),
    'ScheduledTransactionDetails',
  ),
);

export default ScheduledTransactionDetails;
