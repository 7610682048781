import { Box } from '@mui/material';
import {
  FC, FormEvent, ReactElement, ReactNode,
} from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import InputField from 'fields/InputField';
import PhoneField from 'fields/PhoneField';

import { COMPANY_NAME, COMPANY_PHONE_NUMBER } from 'constants/fields';
import { getTranslatedErrors } from 'utils/errorUtils';
import { DummyCompanyFormValues } from 'utils/validation/workspaceFormSchema';

import { Form } from './index.styled';

interface CompanyFormProps {
  register: UseFormRegister<DummyCompanyFormValues>;
  errors: Partial<FieldErrors<DummyCompanyFormValues>>;
  control: Control<DummyCompanyFormValues>;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

const CompanyForm:FC<CompanyFormProps> = ({
  register,
  errors,
  control,
  onSubmit,
}): ReactElement => {
  const intl = useIntl();
  const translatedErrors = getTranslatedErrors(errors);

  return (
    <Form onSubmit={onSubmit}>
      <Box display="flex" flexDirection="column" gap={5}>
        <InputField
          {...COMPANY_NAME}
          label={intl.formatMessage({ id: COMPANY_NAME.label })}
          inputProps={register('name')}
          error={!!translatedErrors?.[COMPANY_NAME.name]?.message}
          helperText={translatedErrors?.[COMPANY_NAME.name]?.message as ReactNode}
          tooltip={intl.formatMessage({ id: COMPANY_NAME.tooltip })}
        />

        <Controller
          name={COMPANY_PHONE_NUMBER.name}
          control={control}
          render={({ field }): ReactElement => (
            <PhoneField
              {...COMPANY_PHONE_NUMBER}
              label={intl.formatMessage({ id: COMPANY_PHONE_NUMBER.label })}
              required
              inputProps={register(COMPANY_PHONE_NUMBER.name)}
              phoneNumber={field.value || ''}
              error={!!errors[COMPANY_PHONE_NUMBER.name]?.message}
              helperText={translatedErrors[COMPANY_PHONE_NUMBER.name]?.message as ReactNode}
            />
          )}
        />
      </Box>

      <input type="submit" hidden />
    </Form>
  );
};

export default CompanyForm;
