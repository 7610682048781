/* eslint max-len: 0 */
import { CustomIconInterface } from 'models/icon.interface';

const BankCardIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.86396 2.16669L1.66699 6.71214V8.22729H16.0609V6.71214L8.86396 2.16669ZM3.18214 8.98487V14.2879H5.45487V8.98487H3.18214ZM7.7276 8.98487V11.2576V12.7727H7.73648C7.78572 11.5356 8.77305 10.5445 10.0003 10.5074V8.98487H7.7276ZM12.2731 8.98487V10.5H14.5458V8.98487H12.2731ZM10.0699 12.0152C9.61305 12.0152 9.24275 12.397 9.24275 12.8674V13.5303H18.3337V12.8674C18.3337 12.397 17.9634 12.0152 17.5065 12.0152H10.0699ZM1.66699 15.0455V17.3182H7.7276V15.0455H1.66699ZM9.24275 15.0455V17.9811C9.24275 18.4515 9.61305 18.8334 10.0699 18.8334H17.5065C17.9634 18.8334 18.3337 18.4515 18.3337 17.9811V15.0455H9.24275Z"
      fill={color}
    />
  </svg>
);

export default BankCardIcon;
