/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const ArchiveIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.19998 2.4C2.31598 2.4 1.59998 3.116 1.59998 4V6.4H10.4H22.4V5.6C22.4 4.716 21.684 4 20.8 4H8.95935L8.4656 3.17656C8.1768 2.69496 7.65613 2.4 7.09373 2.4H3.19998ZM2.39998 8C1.95838 8 1.59998 8.3584 1.59998 8.8V18.4C1.59998 19.284 2.31598 20 3.19998 20H20.8C21.684 20 22.4 19.284 22.4 18.4V8.8C22.4 8.3584 22.0416 8 21.6 8H2.39998Z"
      fill={color}
    />
  </svg>
);

export default ArchiveIcon;
