import { onAuthStateChanged, User as FbUser } from 'firebase/auth';
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect, useMemo,
  useState,
} from 'react';

import Loader from 'components/Loader';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { resetStore } from 'store/actions';
import {
  currentWorkspaceSelector, jwtTokenSelector, userSelector,
} from 'store/user/selectors';
import {
  fetchUserDetails, initializeStoreWithStoredData, resetUserState,
} from 'store/user/userSlice';

import { auth } from 'utils/firebase';

interface AppInfoProviderProps {
  children: ReactNode;
}

const UserContext = createContext<{ fbUser: FbUser|null }>({
  fbUser: null,
});

const UserProvider:FC<AppInfoProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [fbUser, setFbUser] = useState<FbUser|null>(null);
  const user = useAppSelector(userSelector);
  const currentWorkspace = useAppSelector(currentWorkspaceSelector);
  const jwtToken = useAppSelector(jwtTokenSelector);
  const isCustomUserLoading = Boolean(jwtToken?.idToken && (!currentWorkspace || !user));
  const store = useMemo(() => ({
    fbUser,
  }), [fbUser]);

  useEffect(() => {
    const unregisterAuthObserver = onAuthStateChanged(auth, async (userResponse) => {
      if (userResponse) {
        setFbUser(userResponse);
      } else {
        setTimeout(() => resetUser());
      }

      setIsLoading(false);
    });

    return () => {
      unregisterAuthObserver();
    };
  }, []);

  useEffect(() => {
    dispatch(initializeStoreWithStoredData());
  }, []);

  useEffect(() => {
    if (jwtToken?.idToken) {
      dispatch(fetchUserDetails());
    }
  }, [jwtToken?.idToken]);

  const resetUser = useCallback(() => {
    setFbUser(null);
    dispatch(resetStore());
    dispatch(resetUserState());
  }, []);

  return (
    <UserContext.Provider value={store}>
      {isLoading || isCustomUserLoading
        ? <Loader />
        : children}
    </UserContext.Provider>

  );
};

export {
  UserProvider,
  UserContext,
};
