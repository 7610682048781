import { runGetApiRequest, runPostApiRequest } from 'services/Api';

import {
  TRANSACTION_APPROVAL_API,
  TRANSACTION_APPROVAL_CURRENT_USER_API,
  TRANSACTION_APPROVAL_DETAILS_API,
  TRANSACTION_APPROVAL_REVIEW_API,
} from 'constants/apiRoutes';
import { TransactionApprovalStatus } from 'constants/enums';
import { formatRoute } from 'utils/formatters';

export const getTransactionApproval = (queryParams: Record<string, string|number>) => (
  runGetApiRequest(TRANSACTION_APPROVAL_API, queryParams)
);

export const getCurrentUserApprovalTransactions = () => (
  runGetApiRequest(TRANSACTION_APPROVAL_CURRENT_USER_API)
);

export const reviewTransaction = (id: string, payload: { status: TransactionApprovalStatus; note?: string }) => (
  runPostApiRequest(formatRoute(TRANSACTION_APPROVAL_REVIEW_API, { id }), payload)
);

export const getTransactionApprovalDetail = (id: string) => (
  runGetApiRequest(formatRoute(TRANSACTION_APPROVAL_DETAILS_API, { id }))
);
