import { usePermissions } from 'hooks/usePermissions';

import BillingIcon from 'assets/icons/BillingIcon';
import CompanyIcon from 'assets/icons/CompanyIcon';
import NotificationIcon from 'assets/icons/NotificationIcon';
import TeamIcon from 'assets/icons/TeamIcon';
import {
  ALERT_CONTACTS_ROUTE,
  ALERTS_HISTORY_ROUTE,
  ALERTS_RULES_ROUTE,
  COMPANY_BILLING_ROUTE,
  COMPANY_PROFILE_ROUTE,
  COMPANY_TEAM_MANAGEMENT_ROUTE,
} from 'constants/clientRoutes';
import { RolePermissions } from 'constants/permissionEnums';
import { moveMoneyItems, userSettingsItems } from 'constants/sidebar';

export enum SidebarContext {
  UserProfile,
  WorkspaceSettings,
  MoveMoney,
}

const useWorkspaceSettings = () => {
  const { isAllowed } = usePermissions();

  return () => (
    [
      {
        key: 'company-profile',
        Icon: CompanyIcon,
        label: 'navigation.companyProfile',
        route: COMPANY_PROFILE_ROUTE,
        isVisible: true,
      },
      {
        key: 'team-management',
        Icon: TeamIcon,
        label: 'navigation.teamManagement',
        route: COMPANY_TEAM_MANAGEMENT_ROUTE,
        isVisible: isAllowed({ permission: RolePermissions.ViewUsers }),
      },
      {
        key: 'billing',
        Icon: BillingIcon,
        label: 'navigation.billingInformation',
        route: COMPANY_BILLING_ROUTE,
        isVisible: isAllowed({ permission: RolePermissions.ViewSubscription }),
      },
      {
        key: 'alerts',
        Icon: NotificationIcon,
        label: 'navigation.alertCenter',
        isVisible: isAllowed({ permission: RolePermissions.ViewAlertRules }),
        route: '',
        children: [
          {
            key: 'alerts-rules',
            label: 'navigation.alertRules',
            route: ALERTS_RULES_ROUTE,
            isVisible: true,
          },
          {
            key: 'alerts-history',
            label: 'navigation.alertHistory',
            route: ALERTS_HISTORY_ROUTE,
            isVisible: true,
          },
          {
            key: 'alerts-contacts',
            label: 'navigation.alertContacts',
            route: ALERT_CONTACTS_ROUTE,
            isVisible: true,
          },
        ],
      },
    ]
  );
};

export const useSidebar = (context: SidebarContext) => {
  const workspaceSettingsItems = useWorkspaceSettings();

  switch (context) {
    case SidebarContext.UserProfile:
      return userSettingsItems;
    case SidebarContext.WorkspaceSettings:
      return workspaceSettingsItems();
    case SidebarContext.MoveMoney:
      return moveMoneyItems;
    default:
      return [];
  }
};
