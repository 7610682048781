import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const TransactionsActivity = lazy(
  () => CustomLazyLoader(
    () => import(
      /* webpackChunkName: 'TransactionsActivity' */ 'modules/transactions-activity/containers/TransactionsActivity'
    ),
    'TransactionsActivity',
  ),
);

export default TransactionsActivity;
