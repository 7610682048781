import {
  AxiosError, AxiosResponse, InternalAxiosRequestConfig,
} from 'axios';
import { onAuthStateChanged } from 'firebase/auth';

import { getValueFromStorage } from 'hooks/useLocalStorage';
import { axiosInstance, axiosInstanceFB } from 'services/Api';

import envConfig from 'configuration/env';
import { LocalStorageKeys } from 'constants/enums';
import { getApiError } from 'utils/errorUtils';
import { auth } from 'utils/firebase';

const { apiBaseUrl } = envConfig;

axiosInstance.defaults.baseURL = apiBaseUrl;
axiosInstanceFB.defaults.baseURL = apiBaseUrl;

let isUnauthorized = false;

const cleanupUnauthorizedCheck = () => {
  isUnauthorized = false;
};

const getUserToken = async () => new Promise((resolve) => {
  const unsubscribe = onAuthStateChanged(auth, async (userResponse) => {
    if (userResponse) {
      const token = await userResponse.getIdToken();
      resolve(token);
    } else {
      resolve(null);
    }

    unsubscribe();
  });
});

const onRequest = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
  const { idToken } = getValueFromStorage(LocalStorageKeys.jwtToken, null) || {};

  if (idToken) {
    config.headers.Authorization = `Bearer ${idToken}`;
    config.headers['Content-Type'] = config.headers['Content-Type'] || 'application/json';
  }

  return config;
};

const onRequestWithFB = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
  const token = await getUserToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers['Content-Type'] = 'application/json';
  }

  return config;
};

const onResponse = (response: AxiosResponse): AxiosResponse => response;

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  const status = error.status || (error.response ? error.response.status : 0);
  let formattedError = getApiError(error);

  if (status === 401) {
    if (!isUnauthorized) {
      isUnauthorized = true;
      setTimeout(cleanupUnauthorizedCheck, 1000);
      auth.signOut();
    } else {
      formattedError = '';
    }
  }

  if (process.env.REACT_APP_NODE_ENV === 'local') {
    // eslint-disable-next-line no-console
    console.error(`[response error]: ${JSON.stringify(formattedError || error)}`);
  }

  return Promise.reject(formattedError);
};

const setupAxiosInterceptors = () => {
  axiosInstance.interceptors.request.use(onRequest);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);

  axiosInstanceFB.interceptors.request.use(onRequestWithFB);
  axiosInstanceFB.interceptors.response.use(onResponse, onResponseError);
};

export default setupAxiosInterceptors;
