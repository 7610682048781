import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const ScheduledTransactions = lazy(
  () => CustomLazyLoader(
    () => import(
      /* webpackChunkName: 'ScheduledTransactions' */
      'modules/scheduled-transactions/containers/ScheduledTransactions'
    ),
    'ScheduledTransactions',
  ),
);

export default ScheduledTransactions;
