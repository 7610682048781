
const MuiOutlinedInput = {
  styleOverrides: {
    root: ({ theme: muiTheme }: any) => ({
      borderRadius: '4px',

      '&.Mui-focused': {
        backgroundColor: muiTheme.palette.common.white,

        '& .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${muiTheme.palette.primary.main}`,
        },
      },
      '&:hover:not(&.Mui-focused, &.Mui-disabled)': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: muiTheme.palette.border.main,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: muiTheme.palette.border.secondary,
      },
      '&.Mui-disabled': {
        backgroundColor: muiTheme.palette.general.lightGrey9,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: muiTheme.palette.border.secondary,
        },
      },
    }),
    input: ({ theme: muiTheme }: any) => ({
      '&::placeholder': {
        WebkitTextFillColor: muiTheme.palette.general.lightGrey6,
        fontSize: '15px',
      },
    }),
  },
};

export default MuiOutlinedInput;
