import {
  runDeleteApiRequest,
  runGetApiRequest,
  runPatchApiRequest,
  runPostApiRequest,
} from 'services/Api';

import {
  FISERV_SESSION_ID_API,
  CHECK_ACCOUNT_UPDATE_COMPLETED_API,
  IDP_API,
  BANK_ACCOUNTS_API,
  DELETE_BANK_ACCOUNT_API,
  BANK_ACCOUNT_DETAILS_API,
  BANK_ACCOUNT_TRANSACTIONS_API,
  BANK_ACCOUNT_UPDATE_API,
  INFLOW_OUTFLOW_WIDGET_API,
  ACTIVITY_WIDGET_API,
  TRANSACTION_ACTIVITY_WIDGET_API,
  ACCOUNT_BALANCE_RANKING_WIDGET_API,
  ACCOUNT_DETAILS_ACTIVITY_WIDGET_API,
  EXTERNAL_BANK_ACCOUNTS_API,
  CREATE_INTEGRATED_BANK_ACCOUNT_API,
  GROUPED_BANK_TRANSACTIONS_API,
} from 'constants/apiRoutes';
import { BankAccountType } from 'constants/enums';
import { formatRoute } from 'utils/formatters';

export const getFiservSessionId = (workspaceId: string) => (
  runGetApiRequest(formatRoute(FISERV_SESSION_ID_API, { workspaceId }))
);

export const checkAccountUpdates = (workspaceId: string, requestId: string) => {
  const endpoint = formatRoute(CHECK_ACCOUNT_UPDATE_COMPLETED_API, { workspaceId });
  return runGetApiRequest(endpoint, { requestId });
};

export const resyncBankAccount = (workspaceId: string, bankAccountId: string, params?: Record<string, boolean>) => {
  const endpoint = formatRoute(BANK_ACCOUNT_UPDATE_API, { workspaceId, bankAccountId });
  return runGetApiRequest(endpoint, params);
};

export const runAccountUpdatesCompletionCheck = async (workspaceId: string, requestId: string) => {
  let interval: any;
  let count = 0;
  const allowAttempts = 15;

  return new Promise<boolean>((resolve, reject) => {
    interval = setInterval(async () => {
      try {
        count += 1;
        const { data } = await checkAccountUpdates(workspaceId, requestId);

        if (data === true) {
          resolve(data);
        } else if (count > allowAttempts) {
          reject(data);
        }
      } catch (e) {
        reject(e);
      }
    }, 10000);
  }).finally(() => clearInterval(interval));
};

export const getIpdBankAccounts = (workspaceId: string, fiLoginAcctId: string, hideAdded = true) => {
  const endpoint = formatRoute(IDP_API, { workspaceId });
  return runGetApiRequest(endpoint, { fiLoginAcctId, hideAdded });
};

export const getWorkspaceBankAccounts = (workspaceId: string) => {
  const endpoint = formatRoute(BANK_ACCOUNTS_API, { workspaceId });
  return runGetApiRequest(endpoint);
};

export const getCustomerBankAccountDetails = (workspaceId: string, accountId: string) => {
  const endpoint = formatRoute(BANK_ACCOUNT_DETAILS_API, { workspaceId, accountId });
  return runGetApiRequest(endpoint);
};

export const connectBankAccounts = (bankAccountIdpInfoIds : string, workspaceId: string) => {
  const endpoint = formatRoute(EXTERNAL_BANK_ACCOUNTS_API, { workspaceId });
  return runPostApiRequest(endpoint, {}, { bankAccountIdpInfoIds });
};

export const deleteWorkspaceBankAccount = (bankAccountId: string, workspaceId: string) => {
  const endpoint = formatRoute(DELETE_BANK_ACCOUNT_API, { bankAccountId, workspaceId });
  return runDeleteApiRequest(endpoint);
};

export const updateWorkspaceBankAccount = (
  workspaceId: string,
  accountId: string,
  queryParams: { favorite?: boolean; nickname?: string },
) => {
  const endpoint = formatRoute(BANK_ACCOUNT_DETAILS_API, { workspaceId, accountId });
  return runPatchApiRequest(endpoint, {}, queryParams);
};

export const getBankAccountsTransactions = (
  queryParams: { [key: string]: string | number } | URLSearchParams,
  params: { [key: string]: string | number },
) => {
  const endpoint = formatRoute(BANK_ACCOUNT_TRANSACTIONS_API, params);
  return runGetApiRequest(endpoint, queryParams);
};

export const getGroupedBankTransactions = (
  queryParams: { [key: string]: string | number } | URLSearchParams,
  params: { [key: string]: string | number },
) => {
  const endpoint = formatRoute(GROUPED_BANK_TRANSACTIONS_API, params);
  return runGetApiRequest(endpoint, queryParams);
};

export const getInflowOutflowWidget = (
  queryParams: { [key: string]: string | number },
  params: { [key: string]: string | number },
) => {
  const endpoint = formatRoute(INFLOW_OUTFLOW_WIDGET_API, params);
  return runGetApiRequest(endpoint, queryParams);
};

export const getActivityWidget = (
  queryParams: { [key: string]: string | number },
  params: { [key: string]: string | number },
) => {
  const endpoint = formatRoute(ACTIVITY_WIDGET_API, params);
  return runGetApiRequest(endpoint, queryParams);
};

export const getTransactionActivityWidget = (
  queryParams: { [key: string]: string | number },
  params: { [key: string]: string | number },
) => {
  const endpoint = formatRoute(TRANSACTION_ACTIVITY_WIDGET_API, params);
  return runGetApiRequest(endpoint, queryParams);
};

export const getAccountBalanceRankingWidget = (
  params: { [key: string]: string | number },
) => {
  const endpoint = formatRoute(ACCOUNT_BALANCE_RANKING_WIDGET_API, params);
  return runGetApiRequest(endpoint);
};

export const getAccountDetailsActivityWidget = (
  queryParams: { [key: string]: string | number },
  params: { [key: string]: string | number },
) => {
  const endpoint = formatRoute(ACCOUNT_DETAILS_ACTIVITY_WIDGET_API, params);
  return runGetApiRequest(endpoint, queryParams);
};

export const createIntegratedBankAccount = (
  workspaceId: string,
  payload: { nickname: string; subtype: BankAccountType },
) => {
  const endpoint = formatRoute(CREATE_INTEGRATED_BANK_ACCOUNT_API, { workspaceId });
  return runPostApiRequest(endpoint, payload);
};
