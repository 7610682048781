import ActivityIcon from 'assets/icons/ActivityIcon';
import BatchesIcon from 'assets/icons/BatchesIcon';
import PlusBoxIcon from 'assets/icons/PlusBoxIcon';
import ProfileIcon from 'assets/icons/ProfileIcon';
import SecurityIcon from 'assets/icons/SecurityIcon';
import SettingsIcon from 'assets/icons/SettingsIcon';
import {
  MOVE_MONEY_ACTIVITY_ROUTE,
  MOVE_MONEY_BATCHES_ROUTE,
  MOVE_MONEY_ROUTE,
  USER_PREFERENCES_ROUTE,
  USER_PROFILE_ROUTE,
  USER_SECURITY_ROUTE,
} from 'constants/clientRoutes';

export const userSettingsItems = [
  {
    key: 'user-profile',
    Icon: ProfileIcon,
    label: 'navigation.myProfile',
    route: USER_PROFILE_ROUTE,
    isVisible: true,
  },
  {
    key: 'user-security',
    Icon: SecurityIcon,
    label: 'navigation.security',
    route: USER_SECURITY_ROUTE,
    isVisible: true,
  },
  {
    key: 'user-preferences',
    Icon: SettingsIcon,
    label: 'navigation.preferences',
    route: USER_PREFERENCES_ROUTE,
    isVisible: true,
  },
];

export const moveMoneyItems = [
  {
    key: 'createTransaction',
    Icon: PlusBoxIcon,
    label: 'navigation.createTransaction',
    route: MOVE_MONEY_ROUTE,
    isVisible: true,
  },
  {
    key: 'batches',
    Icon: BatchesIcon,
    label: 'navigation.batches',
    route: MOVE_MONEY_BATCHES_ROUTE,
    isVisible: false,
  },
  {
    key: 'transactionActivity',
    Icon: ActivityIcon,
    label: 'navigation.transactionActivity',
    route: MOVE_MONEY_ACTIVITY_ROUTE,
    isVisible: false,
  },
];
