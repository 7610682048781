import { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import useIntercom from 'hooks/useIntercom';
import { useAppSelector } from 'hooks/useRedux';
import { jwtTokenSelector } from 'store/user/selectors';

import { SIGN_IN_ROUTE } from 'constants/clientRoutes';

const ProtectedRoute = (): ReactElement => {
  const jwtToken = useAppSelector(jwtTokenSelector);

  const { closeIntercom, startIntercom } = useIntercom();

  if (!jwtToken?.idToken) {
    closeIntercom();
    return <Navigate to={SIGN_IN_ROUTE} replace />;
  }

  startIntercom();

  return (
    <Outlet />
  );
};

export default ProtectedRoute;
