import MuiMenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

export const MenuItem = styled(MuiMenuItem)(() => ({
  '&.Mui-selected': {
    backgroundColor: 'transparent',
  },
}));

export const Item = styled('div')<{ isOpen: boolean }>(({ theme, isOpen }) => ({
  '& svg': {
    transform: isOpen ? 'rotate(180deg)' : '',
  },
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

export const NavbarLink = styled(NavLink)(() => ({
  textDecoration: 'none',
  whiteSpace: 'nowrap',

  '& li span > *': {
    display: 'none',
  },
}));
