import {
  runDeleteApiRequest,
  runGetApiRequest,
  runGetApiRequestFB,
  runPostApiRequest,
  runPostApiRequestFB,
  runUpdateApiRequest,
} from 'services/Api';

import {
  BACKGROUND_UPDATE_CUSTOMER_BANK_ACCOUNTS_API,
  CREATE_WORKSPACE_API,
  CURRENT_WORKSPACE_API,
  GENERATE_JWT_TOKEN_API,
  LOGGED_USER_WORKSPACES_API,
  REMOVE_WORKSPACE_API,
  UPDATE_WORKSPACE_API,
} from 'constants/apiRoutes';
import { Workspace } from 'models/workspace.interface';
import { formatRoute } from 'utils/formatters';
import { WorkspaceFormValues } from 'utils/validation/workspaceFormSchema';

export const getLoggedUserWorkspaces = async () => (
  runGetApiRequestFB(LOGGED_USER_WORKSPACES_API)
);

export const createWorkspace = (data: WorkspaceFormValues) => (
  runPostApiRequestFB(CREATE_WORKSPACE_API, data)
);

export const removeWorkspace = async (workspaceId: string) => (
  runDeleteApiRequest(formatRoute(REMOVE_WORKSPACE_API, { workspaceId }))
);

export const generateJwtToken = (workspaceId: string) => (
  runPostApiRequestFB(GENERATE_JWT_TOKEN_API, { customerId: workspaceId })
);

export const getCurrentWorkspace = async () => (
  runGetApiRequest(CURRENT_WORKSPACE_API)
);

export const updateWorkspace = async (workspaceId: string, data: Workspace) => (
  runUpdateApiRequest(formatRoute(UPDATE_WORKSPACE_API, { workspaceId }), data)
);

export const updateCustomerBankAccountsInBackground = async (workspaceId: string) => {
  try {
    await runPostApiRequest(formatRoute(BACKGROUND_UPDATE_CUSTOMER_BANK_ACCOUNTS_API, { workspaceId }));
  } catch (e) {
    console.log('error while trying to update customer account', e); // eslint-disable-line
  }
};
