import { isAxiosError } from 'axios';
import {
  FieldErrors, FieldValues, UseFormSetError, DeepRequired, FieldErrorsImpl,
} from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import { GENERAL_TOAST_OPTIONS } from 'constants/general';
import { ApiFieldError } from 'models/errors.interface';

export const getApiError = (e: any) => {
  const status = e.status || (e.response ? e.response.status : 0);

  if (status === 401) {
    return {
      message: 'error.sessionExpired',
      title: 'Your session has expired!',
      status: 401,
    };
  }

  if (isAxiosError(e) && e?.response?.data) {
    return {
      ...e?.response?.data,
      status,
    };
  }

  if (e?.detail) {
    return {
      ...e,
      status,
    };
  }

  return {
    message: e?.response?.statusText || 'Something went wrong!',
    title: e?.message || 'Something went wrong!',
    status,
  };
};

export const handleApiErrors = <TValue extends FieldValues>(error: any, setError?: UseFormSetError<TValue>) => {
  if (error.fieldErrors && setError) {
    error.fieldErrors.forEach((fieldError: ApiFieldError) => {
      const typesafeKey = fieldError.field as keyof UseFormSetError<TValue>;
      setError(typesafeKey, {
        message: fieldError.message,
        type: 'custom',
      });
    });
  } else {
    toast.error(error?.title, GENERAL_TOAST_OPTIONS);
  }
};

export const getTranslatedErrors = <T extends FieldValues>(
  errors: Partial<FieldErrors<T>>,
): Partial<FieldErrors<T>> => {
  let formattedErrors = {} as Partial<FieldErrors<T>>;
  type ErrorKey = keyof T;

  Object.keys(errors).forEach((value) => {
    const key = value as ErrorKey;
    const errorAsArray = errors[key] as FieldErrorsImpl<DeepRequired<string[]>>;

    if (errorAsArray?.length) {
      errorAsArray.forEach((e, index: number) => {
        if (e?.message) {
          const message = e?.message as string;

          formattedErrors = {
            ...formattedErrors,
            [`${key.toString()}-${index}`]: {
              ...[e],
              message: <FormattedMessage id={message} />,
            },
          };
        }
      });
    }

    if (errors[key]?.message) {
      const message = errors[key]?.message as string;

      formattedErrors = {
        ...formattedErrors,
        [key]: {
          ...errors[key],
          message: <FormattedMessage id={message} />,
        },
      };
    }
  });

  return formattedErrors;
};
