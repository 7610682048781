/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const AlertIcon = ({ size = 69, color = '#FF6E6E' }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 69 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M57.1668 33.9997C57.1668 38.4827 55.8375 42.8651 53.3468 46.5926C50.8562 50.3201 47.3161 53.2254 43.1743 54.9409C39.0325 56.6565 34.475 57.1054 30.0781 56.2308C25.6812 55.3562 21.6424 53.1974 18.4724 50.0274C15.3024 46.8574 13.1436 42.8186 12.269 38.4217C11.3944 34.0248 11.8433 29.4673 13.5589 25.3255C15.2745 21.1837 18.1797 17.6437 21.9072 15.153C25.6348 12.6624 30.0171 11.333 34.5002 11.333C40.5117 11.333 46.2771 13.7211 50.5279 17.9719C54.7787 22.2227 57.1668 27.9881 57.1668 33.9997ZM34.5002 38.5694C33.6689 38.5694 32.8564 38.8159 32.1652 39.2777C31.4741 39.7395 30.9354 40.3959 30.6173 41.1638C30.2992 41.9318 30.216 42.7768 30.3781 43.5921C30.5403 44.4073 30.9406 45.1562 31.5284 45.744C32.1161 46.3317 32.865 46.732 33.6802 46.8942C34.4955 47.0563 35.3405 46.9731 36.1085 46.655C36.8765 46.3369 37.5328 45.7982 37.9946 45.1071C38.4565 44.4159 38.7029 43.6034 38.7029 42.7721C38.7029 41.6575 38.2602 40.5885 37.472 39.8003C36.6838 39.0122 35.6148 38.5694 34.5002 38.5694ZM30.5085 23.4583L31.1866 35.8886C31.2015 36.169 31.3233 36.433 31.527 36.6263C31.7308 36.8196 32.0009 36.9274 32.2817 36.9275H36.7206C37.0011 36.9269 37.2707 36.8189 37.474 36.6257C37.6773 36.4324 37.7989 36.1687 37.8138 35.8886L38.4919 23.4583C38.5 23.3094 38.4776 23.1605 38.4262 23.0206C38.3749 22.8807 38.2955 22.7527 38.193 22.6445C38.0906 22.5363 37.9671 22.4501 37.8302 22.3912C37.6933 22.3322 37.5458 22.3018 37.3968 22.3018H31.6036C31.4545 22.3018 31.307 22.3321 31.1701 22.391C31.0331 22.4499 30.9096 22.5361 30.8071 22.6444C30.7047 22.7526 30.6253 22.8806 30.574 23.0205C30.5226 23.1605 30.5003 23.3094 30.5085 23.4583Z"
      fill={color}
    />
  </svg>
);

export default AlertIcon;
