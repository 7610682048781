
const MuiTabs = {
  styleOverrides: {
    root: ({ theme }: any) => ({
      alignSelf: 'end',
      minHeight: '22px',
      borderBottom: `1px solid ${theme.palette.border.secondary}`,
    }),
    indicator: ({ theme }: any) => ({
      display: 'flex',
      justifyContent: 'center',
      height: '4px',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '10px 10px 0px 0px',
      left: theme.spacing(6),
    }),
  },
};

export default MuiTabs;
