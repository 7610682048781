import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getTransactions } from 'services/TransactionService';
import { resetStore } from 'store/actions';

import { ApiStatus, DefaultType, SortOrder } from 'constants/enums';
import { Pagination } from 'models/pagination.interface';
import { SortOptions } from 'models/sortOptions.interface';
import { TransactionDTO } from 'models/transaction.interface';
import { handleApiErrors } from 'utils/errorUtils';
import { ActivityTransactionFilterSchema } from 'utils/validation/transactionFormSchema';

export interface TransactionSearchState {
  details: {
    data: TransactionDTO[];
    status: ApiStatus;
  };
  sortOptions: SortOptions;
  pagination: Pagination;
  filters: ActivityTransactionFilterSchema;
}

const initialState: TransactionSearchState = {
  details: {
    data: [],
    status: ApiStatus.idle,
  },
  sortOptions: {
    name: 'createdAt',
    type: SortOrder.desc,
  },
  pagination: {
    totalPages: 0,
    totalElements: 0,
    page: 1,
  },
  filters: {
    latestStatus: DefaultType.all,
    dateRange: {
      from: null,
      to: null,
    },
    amountRange: {
      min: null,
      max: null,
    },
  },
};

export const fetchTransactions = createAsyncThunk(
  'transactionActivity/fetchTransactions',
  async (queryParams: { page?: number; sort?: string; search?: string; size?: number }, thunkAPI) => {
    try {
      const response = await getTransactions(queryParams);
      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const transactionActivitySlice = createSlice({
  name: 'transactionActivity',
  initialState,
  reducers: {
    setPagination: (state, action: PayloadAction<Partial<Pagination>>) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
      return state;
    },
    setSort: (state, action: PayloadAction<SortOptions>) => {
      state.sortOptions = action.payload;
      return state;
    },
    setFilters: (state, action: PayloadAction<ActivityTransactionFilterSchema>) => {
      state.filters = action.payload;
      state.pagination.page = 1;
      return state;
    },
    resetTransactions: (state) => ({
      ...state,
      details: initialState.details,
      pagination: initialState.pagination,
    }),
    resetTransactionsStore: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(fetchTransactions.pending, (state) => {
        state.details = {
          ...state.details,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        const {
          totalElements, totalPages, content,
        } = action.payload;

        state.details = {
          ...state.details,
          data: content,
          status: ApiStatus.idle,
        };
        state.pagination = {
          ...state.pagination,
          totalPages,
          totalElements,
        };
      })
      .addCase(fetchTransactions.rejected, (state) => {
        state.details = {
          ...state.details,
          status: ApiStatus.idle,
        };
      });
  },
});

export const {
  setPagination,
  setSort,
  setFilters,
  resetTransactions,
  resetTransactionsStore,
} = transactionActivitySlice.actions;

export default transactionActivitySlice.reducer;
