const MuiInputBase = {
  styleOverrides: {
    inputSizeSmall: {
      height: '28px',
    },
    root: {
      borderRadius: '4px',
      '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },

      '& input[type=number]': {
        MozAppearance: 'textfield',
      },
    },
  },
};

export default MuiInputBase;
