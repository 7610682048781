/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const SuccessIcon = ({ size = 69 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 69 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M57.1668 33.9997C57.1668 38.4827 55.8375 42.8651 53.3468 46.5926C50.8562 50.3201 47.3161 53.2253 43.1743 54.9409C39.0325 56.6565 34.475 57.1054 30.0781 56.2308C25.6812 55.3562 21.6424 53.1974 18.4724 50.0274C15.3024 46.8574 13.1436 42.8186 12.269 38.4217C11.3944 34.0248 11.8433 29.4673 13.5589 25.3255C15.2745 21.1837 18.1797 17.6437 21.9072 15.153C25.6348 12.6624 30.0171 11.333 34.5002 11.333C37.4768 11.333 40.4243 11.9193 43.1743 13.0584C45.9244 14.1975 48.4231 15.8671 50.5279 17.9719C52.6327 20.0767 54.3023 22.5755 55.4414 25.3255C56.5805 28.0756 57.1668 31.023 57.1668 33.9997ZM31.8788 46.0017L48.6952 29.1841C48.831 29.0484 48.9388 28.8871 49.0124 28.7097C49.0859 28.5323 49.1238 28.3421 49.1238 28.15C49.1238 27.9579 49.0859 27.7677 49.0124 27.5902C48.9388 27.4128 48.831 27.2516 48.6952 27.1158L46.6268 25.0463C46.4911 24.9105 46.3298 24.8027 46.1524 24.7291C45.975 24.6556 45.7848 24.6178 45.5927 24.6178C45.4006 24.6178 45.2104 24.6556 45.0329 24.7291C44.8555 24.8027 44.6943 24.9105 44.5585 25.0463L30.8452 38.7597L24.4418 32.3563C24.3061 32.2205 24.1448 32.1127 23.9674 32.0391C23.79 31.9656 23.5998 31.9277 23.4077 31.9277C23.2156 31.9277 23.0254 31.9656 22.8479 32.0391C22.6705 32.1127 22.5093 32.2205 22.3735 32.3563L20.3052 34.4247C20.1693 34.5604 20.0615 34.7217 19.988 34.8991C19.9144 35.0766 19.8766 35.2668 19.8766 35.4588C19.8766 35.6509 19.9144 35.8411 19.988 36.0186C20.0615 36.196 20.1693 36.3572 20.3052 36.493L29.8104 45.9983C29.9462 46.1341 30.1074 46.2419 30.2849 46.3155C30.4623 46.389 30.6525 46.4269 30.8446 46.4269C31.0367 46.4269 31.2269 46.389 31.4043 46.3155C31.5818 46.2419 31.743 46.1341 31.8788 45.9983V46.0017Z"
      fill="#43CA21"
    />
  </svg>
);

export default SuccessIcon;
