import { isNil } from 'lodash';

export const formatQueryParamsAsSearchParams = (filters: Record<string, any>) => {
  const searchParams: URLSearchParams = new URLSearchParams();
  const { sortOptions, ...remainingFilters } = filters;

  (Object.keys(remainingFilters) as (keyof typeof filters)[]).forEach((key) => {
    const filterValue = remainingFilters[key];
    if (!isNil(filterValue)) {
      searchParams.set(key, filterValue.toString());
    }
  });

  if (remainingFilters?.sort && remainingFilters?.sort?.includes('firstName')) {
    searchParams.append('sort', `businessName,${sortOptions?.type}`);
  }

  return searchParams;
};
