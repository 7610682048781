import {
  Container,
  Paper,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import CustomButton from 'components/CustomButton';
import { ImageBox, CardMedia, Actions } from 'components/KybCover/index.styled';

import MoveMoneyPreview from 'assets/images/move-money-preview.png';
import { DASHBOARD_ROUTE, KYB_CREATION_ROUTE } from 'constants/clientRoutes';
import { KybSteps } from 'constants/enums';
import { formatRoute } from 'utils/formatters';

interface KybCoverProps {
  skipLabel?: string;
  contentLabel?: string;
  onClose?: () => void;
}

const KybCover: FC<KybCoverProps> = ({
  skipLabel = 'button.skipForNow',
  contentLabel = 'label.pleaseFillOutLegalDetails',
  onClose,
}) => {
  const navigate = useNavigate();
  const intl = useIntl();

  const startKybSetup = () => {
    navigate(formatRoute(KYB_CREATION_ROUTE, { step: KybSteps.companyDetails }));
  };

  const seeDashboard = () => {
    navigate(DASHBOARD_ROUTE);

    if (onClose) {
      onClose();
    }
  };

  const newLine = () => <br />;

  return (
    <Container maxWidth="md" sx={{ zIndex: 100 }}>
      <Paper elevation={0} className="medium" sx={{ position: 'relative', border: 0 }}>
        <ImageBox>
          <CardMedia
            component="img"
            src={MoveMoneyPreview}
            alt="Primary account"
          />
        </ImageBox>
        <Container sx={{ width: '400px' }}>
          <Typography variant="h2" textAlign="center" mb={3}>
            {intl.formatMessage(
              { id: 'label.gainAccessTo' },
              { br: newLine },
            )}
          </Typography>
          <Typography mb={7} textAlign="center" color="general.darkGrey1">
            {intl.formatMessage({ id: contentLabel })}
          </Typography>

          <Actions>
            <CustomButton
              label={intl.formatMessage({ id: 'button.startCompanyProfile' })}
              id="button-startCompanyProfile"
              onClick={startKybSetup}
            />
            <CustomButton
              variant="text"
              label={intl.formatMessage({ id: skipLabel })}
              id="button-skipForNow"
              onClick={seeDashboard}
            />
          </Actions>
        </Container>
      </Paper>
    </Container>
  );
};

export default KybCover;
