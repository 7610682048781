import { captureException } from '@sentry/react';
import {
  useRef, useState, ReactElement, FC,
} from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import useLocalStorage from 'hooks/useLocalStorage';
import { useAppSelector } from 'hooks/useRedux';
import { getFiservSessionId } from 'services/BankAccountService';
import { currentWorkspaceSelector } from 'store/user/selectors';

import envConfig from 'configuration/env';
import { BANK_ACCOUNTS_TEMPORARY_ROUTE } from 'constants/clientRoutes';
import { FiservRouteType, LocalStorageKeys } from 'constants/enums';
import { GENERAL_TOAST_OPTIONS } from 'constants/general';
import { handleApiErrors } from 'utils/errorUtils';
import { formatRoute } from 'utils/formatters';

interface AddAccountProps {
  children: (generateFiservSessionId: () => Promise<void>, isLoading: boolean) => ReactElement;
  type?: string;
}

const AddAccount:FC<AddAccountProps> = ({ children, type = FiservRouteType.add }) => {
  const intl = useIntl();
  const fiFormRef = useRef<HTMLFormElement | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [fiservSession, setFiservSession] = useLocalStorage(LocalStorageKeys.fiservSessionId);

  const currentWorkspace = useAppSelector(currentWorkspaceSelector);

  const generateFiservSessionId = async () => {
    if (!currentWorkspace?.id) {
      toast.warning(intl.formatMessage({ id: 'label.noCustomer' }), GENERAL_TOAST_OPTIONS);
    } else {
      setIsLoading(true);

      try {
        const { data } = await getFiservSessionId(currentWorkspace.id);
        setFiservSession(data);
        setTimeout(() => fiFormRef.current?.submit(), 100);
        setTimeout(() => setIsLoading(false), 1000);
      } catch (e: any) {
        captureException(e);
        handleApiErrors(e);
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      {children(generateFiservSessionId, isLoading)}
      <form ref={fiFormRef} target="_self" action={envConfig.fiServEnvUrl} method="post">
        <input type="hidden" name="sessionToken" value={fiservSession?.sessionId} />
        <input
          type="hidden"
          name="return_url"
          value={`${envConfig.uiBaseUrl}${formatRoute(BANK_ACCOUNTS_TEMPORARY_ROUTE, { type })}`}
        />
        <input
          type="hidden"
          name="error_url"
          value={envConfig.uiBaseUrl}
        />
        <input
          type="hidden"
          name="css_url"
          value={`${envConfig.uiBaseUrl}/fiserv/fiserv-custom.css`}
        />
        <input
          type="hidden"
          name="invocation_mode"
          value="popup"
        />
      </form>
    </div>
  );
};

export default AddAccount;
