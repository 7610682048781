import { useContext, useEffect } from 'react';

import { UserContext } from 'context/UserContext';

import envConfig from 'configuration/env';

const { intercomAppId } = envConfig;

const useIntercom = () => {
  const { fbUser } = useContext(UserContext);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
      (function(){
        var w=window;
        var ic=w.Intercom;
        if(typeof ic==="function"){
          ic('reattach_activator');
          ic('update',w.intercomSettings);
        } else {
          var d=document;
          var i=function(){i.c(arguments);};
          i.q=[];
          i.c=function(args){i.q.push(args);};
          w.Intercom=i;
          var l=function(){
            var s=d.createElement('script');
            s.type='text/javascript';
            s.async=true;
            s.src='https://widget.intercom.io/widget/${intercomAppId}';
            var x=d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s,x);
          };
          if(document.readyState==='complete'){
            l();
          } else if(w.attachEvent){
            w.attachEvent('onload',l);
          } else {
            w.addEventListener('load',l,false);
          }
        }
      })();
    `;

    // Append the script to the document body
    document.head.appendChild(script);

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const startIntercom = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: intercomAppId,
      name: fbUser?.displayName,
      email: fbUser?.email,
      user_id: fbUser?.uid,
    });
  };

  const closeIntercom = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.Intercom('shutdown');
  };

  return {
    startIntercom,
    closeIntercom,
  };
};

export default useIntercom;
