import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const TransactionsSearch = lazy(
  () => CustomLazyLoader(
    () => import(
      /* webpackChunkName: 'TransactionsSearch' */ 'modules/transactions-search/containers/TransactionsSearch'
    ),
    'TransactionsSearch',
  ),
);

export default TransactionsSearch;
