import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getRecipients } from 'services/RecipientService';
import { resetStore } from 'store/actions';

import { ApiStatus, SortOrder } from 'constants/enums';
import { Pagination } from 'models/pagination.interface';
import { RecipientListDTO } from 'models/recipient.interface';
import { SortOptions } from 'models/sortOptions.interface';
import { formatQueryParamsAsSearchParams } from 'modules/recipients/utils';
import { handleApiErrors } from 'utils/errorUtils';

export interface ScheduledTransactionsState {
  details: {
    data: RecipientListDTO[];
    status: ApiStatus;
  };
  sortOptions: SortOptions;
  pagination: Pagination;
  search: string;
}

const initialState: ScheduledTransactionsState = {
  details: {
    data: [],
    status: ApiStatus.idle,
  },
  sortOptions: {
    name: 'createdAt',
    type: SortOrder.desc,
  },
  pagination: {
    totalPages: 0,
    totalElements: 0,
    page: 1,
  },
  search: '',
};

export const fetchRecipients = createAsyncThunk(
  'recipients/fetchRecipients',
  async (queryParams: { page: number; sort?: string; search?: string; sortOptions?: SortOptions }, thunkAPI) => {
    try {
      const formattedQueryParams = formatQueryParamsAsSearchParams(queryParams);
      const response = await getRecipients(formattedQueryParams);
      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const recipientsSlice = createSlice({
  name: 'recipients',
  initialState,
  reducers: {
    setPagination: (state, action: PayloadAction<Partial<Pagination>>) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
      return state;
    },
    setSort: (state, action: PayloadAction<SortOptions>) => {
      state.sortOptions = action.payload;
      return state;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
      return state;
    },
    resetRecipients: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(fetchRecipients.pending, (state) => {
        state.details = {
          ...state.details,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchRecipients.fulfilled, (state, action) => {
        const {
          totalElements, totalPages, content,
        } = action.payload;

        state.details = {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data: content,
          status: ApiStatus.idle,
        };
        state.pagination = {
          ...state.pagination,
          totalPages,
          totalElements,
        };
      })
      .addCase(fetchRecipients.rejected, (state) => {
        state.details = {
          ...state.details,
          status: ApiStatus.idle,
        };
      });
  },
});

export const {
  setPagination,
  setSort,
  setSearch,
  resetRecipients,
} = recipientsSlice.actions;

export default recipientsSlice.reducer;
