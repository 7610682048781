import MuiBox from '@mui/material/Box';
import { alpha, styled, Theme } from '@mui/material/styles';

import { BannerType } from 'constants/enums';

const getBannerStyling = (theme: Theme, bannerType: BannerType) => {
  if (BannerType.warning === bannerType) {
    return {
      iconPath: theme.palette.warning.secondary,
      backgroundColor: alpha(theme.palette.warning.secondary, 0.2),
      border: `1px solid ${theme.palette.warning.secondary}`,
    };
  }

  return {
    iconPath: theme.palette.error.main,
    backgroundColor: alpha(theme.palette.error.main, 0.2),
    border: `1px solid ${theme.palette.error.main}`,
  };
};

export const IconWrapper = styled('div')<{ bannerType: BannerType }>(({ theme, bannerType }) => {
  const bannerStyling = getBannerStyling(theme, bannerType);

  return {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(3),
    '& path': {
      fill: bannerStyling.iconPath,
    },
  };
});

export const BannerWrapper = styled(
  MuiBox,
  { shouldForwardProp: (prop) => prop !== 'bannerType' },
)<{ bannerType: BannerType }>(({ theme, bannerType }) => {
  const bannerStyling = getBannerStyling(theme, bannerType);

  return {
    padding: theme.spacing(4, 5),
    backgroundColor: bannerStyling.backgroundColor,
    border: bannerStyling.border,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
});
